import { useField } from "formik";
import "./styles.css";
import { MyTextAreaFieldInterface } from "./inputs.interface";
import React from "react";

export default function MyTextAreaField({
  label,
  isRequired,
  inputSize,
  ...props
}: MyTextAreaFieldInterface) {
  const [field, meta] = useField(props);
  let isError = meta.touched && meta.error;

  return (
    <div className="input_container">
      <label
        htmlFor={props?.name}
        className={`input_label  ${
          inputSize === "sm"
            ? "text-[12px]"
            : inputSize === "md"
            ? "text-[14px]"
            : "text-[16px]"
        }`}
      >
        {label}
        {isRequired && <span className={"text-red-500 "}>*</span>}
      </label>
      <textarea
        id={props?.name}
        className={` input_style h-auto rounded-md border-gray-200 border-2  ${
          isError && "border-red-500"
        }   ${
          inputSize === "sm"
            ? "text-[12px] "
            : inputSize === "md"
            ? "text-[14px]"
            : "text-[16px]"
        }`}
        {...field}
        {...props}
        rows={3}
        placeholder={props?.placeholder || "Type here..."}
      />
      {isError && <span className="input_textError">* {meta.error}</span>}
    </div>
  );
}
