import React, { useContext, useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { AppContext, QuizSessionContext } from "context";

import { useNavigate, useParams } from "react-router-dom";
import { IconTypeEnum, ParamStringEnum, QuestionInterface } from "interfaces";
import { getIconHelper } from "utils";
import { PageLinks } from "routes";
import { BoxModal, MyButton } from "components";
import { useConfirmHook } from "hooks";

function PlaygroundPage() {
  const params = useParams<ParamStringEnum>();
  const { confirm } = useConfirmHook();
  const sessionID = params.ID;
  const navigate = useNavigate();
  const TimeIcon = getIconHelper(IconTypeEnum.CLOCK);
  const {
    handlers: { getCurrentProfileHandler },
  } = useContext(AppContext);
  const { isLoading, quizSession, getQuizSessionHandler, submitAnswerHandler } =
    useContext(QuizSessionContext);
  const [questions, setQuestions] = useState<QuestionInterface[]>([]);
  const [isCompleted, setCompleted] = useState(false);
  const [isGeneratingFinalResult, setGenerateFinalResult] = useState(false);
  const remainingQuestions = questions?.filter((e) => !e?.isAttempted);
  const [selectedOption, setSelectedOption] = useState<number>();
  const onSkipHandler = async () => {
    const isConfirm = await confirm("Are you sure?");
    if (isConfirm) {
      navigate(PageLinks.quizSession.pendingQuiz);
    }
  };
  useEffect(() => {
    (async () => {
      const data = await getQuizSessionHandler(sessionID);
      if (data?.isCompleted) {
        await onGameEndHandler();
      }
    })();
  }, []);
  useEffect(() => {
    if (quizSession?._id) {
      setQuestions(quizSession?.quiz.question);
    }
  }, [quizSession]);

  const onGameEndHandler = async () => {
    setGenerateFinalResult(true);
    await getQuizSessionHandler(sessionID);
    getCurrentProfileHandler();
    setGenerateFinalResult(false);
    setCompleted(true);
  };
  const onSubmitQuestion = async (questionID: string, answer) => {
    await submitAnswerHandler(
      {
        auditQuiz: sessionID,
        question: questionID,
        submittedAnswer: answer,
      },
      {
        async onSuccess(payload?: any) {
          // updating question isAttempted status
          let tempQuestions = [...questions];
          let selectedQuestionDetails = tempQuestions.find(
            (e) => e?._id === questionID
          );
          selectedQuestionDetails.isAttempted = true;
          setQuestions(tempQuestions);
          setSelectedOption(null);
          if (remainingQuestions?.length === 1) {
            await onGameEndHandler();
          }
        },
      }
    );
  };
  const selectedQuestion = remainingQuestions?.[0];
  const remainingSeconds = useMemo(() => {
    return Date.now() + quizSession?.secondsRemain * 1000;
  }, [quizSession]);
  return (
    <div
      className={
        "fixed overflow-y-scroll hideScroll top-0 left-0 w-full h-full bg-white pb-20"
      }
    >
      <div
        className={
          "fixed top-0 left-0 w-full flex justify-between  items-center p-5 lg:px-20 px-10 sm:px-2 bg-gray-50"
        }
      >
        <b className={"text-[22px] w-[50%] overflow-hidden truncate"}>
          {quizSession?.quiz?.title}
        </b>
        <div className={"flex gap-5 sm:gap-2 items-center"}>
          {!isLoading && (
            <div
              className={
                "flex items-center gap-2 rounded-full bg-blue-500 text-white font-bold text-[18px] px-5 py-1 "
              }
            >
              <TimeIcon />
              <Countdown
                autoStart
                onComplete={!isCompleted && onGameEndHandler}
                date={remainingSeconds}
                renderer={({ seconds, minutes, hours }) => {
                  return (
                    <span className={"whitespace-nowrap"}>
                      {hours < 10 ? `0${hours}` : hours} :{" "}
                      {minutes < 10 ? `0${minutes}` : minutes} :{" "}
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  );
                }}
              />
            </div>
          )}
          {!isLoading && (
            <span
              className={"cursor-pointer text-red-500"}
              onClick={() => onSkipHandler()}
            >
              Skip
            </span>
          )}
        </div>
      </div>
      {isLoading || isGeneratingFinalResult ? (
        <div
          className={
            "flex items-center h-full justify-center font-bold text-[24px] text-center text-blue-300"
          }
        >
          {isGeneratingFinalResult
            ? "Hold tight, we're tallying your scores and preparing your personalized results. It's the moment of truth!"
            : " Quiz time approaching... Buckle up and get ready for liftoff!"}
        </div>
      ) : (
        <div className={"flex flex-col gap-20 mt-20"}>
          <div className={"text-right px-20 sm:px-2 text-[16px] text-gray-500"}>
            {questions?.length - remainingQuestions?.length + 1} of{" "}
            {questions?.length} Questions
          </div>
          <div className={"flex justify-center items-center "}>
            <div className={"flex flex-col gap-20 lg:w-[800px] w-[500px]"}>
              <div className={"text-center"}>
                <b
                  className={
                    "text-[25px] sm:text-[18px] font-bold text-center "
                  }
                >
                  {selectedQuestion?.question}
                </b>
              </div>
              <div
                className={
                  "grid lg:grid-cols-2 grid-cols-1 gap-5 sm:px-2 lg:gap-10"
                }
              >
                {selectedQuestion?.options?.map((e, key) => {
                  const isSelected = key + 1 === selectedOption;
                  return (
                    <div
                      className={`${
                        isSelected ? "bg-blue-500 text-white" : "bg-blue-50"
                      }  p-5 sm:p-2 rounded-md cursor-pointer`}
                      key={key}
                      onClick={() => setSelectedOption(key + 1)}
                    >
                      <p className={"text-justify"}>
                        <b>{String.fromCharCode(key + 65)}. </b>
                        {e?.text}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className={"flex justify-end"}>
                {selectedOption && (
                  <MyButton
                    colorType={"primary"}
                    name={"Next"}
                    onClick={() =>
                      onSubmitQuestion(selectedQuestion?._id, selectedOption)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isCompleted && (
        <BoxModal
          title={""}
          closeHandler={() => navigate(PageLinks.quizSession.pendingQuiz)}
        >
          <div className={"flex items-center flex-col gap-10"}>
            <b
              className={
                "text-blue-500 text-[30px] sm:text-[20px] font-bold uppercase"
              }
            >
              GAME SUMMARY
            </b>
            <div className={"grid grid-cols-2 gap-5 divide-x-2"}>
              <div className={"flex flex-col gap-5 text-center px-5"}>
                <b className={"text-[22px]"}>
                  {quizSession?.totalPointReceived || 0}
                </b>
                <span className={"text-gray-500"}>Reward Point</span>
              </div>
              <div className={"flex flex-col gap-5 text-center px-5 sm:px-2"}>
                <b className={"text-[22px]"}>
                  {quizSession?.correctAnswerCount || 0}/
                  {quizSession?.quiz?.question?.length || 0}
                </b>
                <span className={"text-gray-500"}>Score</span>
              </div>
            </div>
            <b
              className={"text-[26px] sm:text-[18px] text-gray-500 text-center"}
            >
              {quizSession?.quiz?.title}
            </b>
            <MyButton
              name={"DONE!"}
              onClick={() => navigate(PageLinks.quizSession.pendingQuiz)}
            />
          </div>
        </BoxModal>
      )}
    </div>
  );
}

export default PlaygroundPage;
