import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { QuizReportContext } from "context";
import { PaginationComponent, TableComponent } from "components";
import { QuizAuditInterface, TableColumnInterface } from "interfaces";
import { usePaginationQueryHooks } from "hooks";
import moment from "moment";

function QuizReportPage() {
  const { getAllDataHandlers, generateCSV, isLoading, allData, totalDocs } =
    useContext(QuizReportContext);
  const { calcSNHandler, currentPage } = usePaginationQueryHooks();

  useEffect(() => {
    getAllDataHandlers();
  }, [currentPage]);
  function StudentTable(): TableColumnInterface<QuizAuditInterface>[] {
    return [
      {
        title: "SN",
        render(renderData, key: number) {
          return <div>{calcSNHandler(key)}.</div>;
        },
      },
      {
        title: "Quiz ID",
        render(renderData) {
          return <span>{renderData?.quiz?.quizID}</span>;
        },
      },
      {
        title: "Student ID",
        render(renderData) {
          return <span>{renderData?.student?.studentID}</span>;
        },
      },
      {
        title: "Student Name",
        render(renderData) {
          return (
            <span>{`${renderData?.student?.firstName || ""} ${
              renderData?.student?.middleName || ""
            } ${renderData?.student?.lastName || ""}`}</span>
          );
        },
      },
      {
        title: "Result",
        render(renderData) {
          return (
            <span>{`${renderData?.correctAnswerCount}/${renderData?.quiz?.question?.length}`}</span>
          );
        },
      },
      {
        title: "Learner's Coin Earned",
        render(renderData) {
          return <span>{`${renderData?.totalPointReceived}`}</span>;
        },
      },
      // {
      //   title: "Crypto Coin",
      //   render(renderData) {
      //     return <span>{`${renderData?.totalCryptoCoinEarned}`}</span>;
      //   },
      // },
      {
        title: "Date",
        render(renderData) {
          return (
            <div className={"flex flex-col gap-2 text-[12px]"}>
              <span>
                Assigned at:{" "}
                {moment(renderData?.assignedAt).format("YYYY/MM/DD hh:mm A")}
              </span>
              {renderData?.isCompleted && (
                <span>
                  Completed at:{" "}
                  {moment(renderData?.completedAt).format("YYYY/MM/DD hh:mm A")}
                </span>
              )}
            </div>
          );
        },
      },
    ];
  }

  return (
    <PageTemplate title={"Quiz Report"} isLoading={isLoading} exportXlsx={{
      generateHandler: () => generateCSV(),
    }}>
      <TableComponent
        column={StudentTable()}
        dataSource={allData}
        loading={{
          isLoading: isLoading,
        }}
      />
      <PaginationComponent totalDocs={totalDocs} />
    </PageTemplate>
  );
}

export default QuizReportPage;
