import React, { useContext, useEffect, useState } from "react";
import { IconTypeEnum, QuizInterface, StudentInterface } from "interfaces";
import { FieldArray, useFormikContext } from "formik";
import { Api } from "services";
import { apiUrl, getIconHelper } from "utils";
import { AppContext, QuizContext } from "context";
import { BoxModal, MyButton, FormSubmitButtonsComponent } from "components";

function AssignStudentsModal({ isEdit }: { isEdit: boolean }) {
  const [isOpen, setOpen] = useState(false);
  const { assignQuizHandler, revokeStudentQuizHandler } =
    useContext(QuizContext);

  const [students, setStudents] = useState<StudentInterface[]>([]);
  const { values, setFieldValue } =
    useFormikContext<QuizInterface<string, string>>();
  const [studentObject, setStudentObject] = useState<{
    [sID: string]: Partial<StudentInterface>;
  }>({});

  const { getApi } = Api();
  const CheckIcon = getIconHelper(IconTypeEnum.CHECK);
  const UnCheckIcon = getIconHelper(IconTypeEnum.UN_CHECK);
  const {
    handlers: { setLoadingState, setErrorState },
  } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        setLoadingState(true);
        const res = await getApi(apiUrl.student.get_student);
        const totalData = res?.data?.docs;
        setStudents(totalData);
        let studentObj = {};
        totalData?.forEach((e) => {
          studentObj[e?._id] = e;
        });
        setStudentObject(studentObj);
      } catch (err) {
        setErrorState(true, err?.message);
      } finally {
        setLoadingState(false);
      }
    })();
  }, []);
  const [selectedStudents, setSelectedStudents] = useState([]);
  let selectableStudent = students;
  if (isEdit) {
    selectableStudent = students?.filter(
      (e) => !values?.students?.some((_e) => _e === e?._id)
    );
  }
  const selectStudentHandler = (id) => {
    const selectedIndex = selectedStudents?.findIndex((_e) => _e === id);
    const isSelected = selectedIndex > -1 ? true : false;
    if (isSelected) {
      setSelectedStudents((e) => e?.filter((_e) => _e !== id));
    } else {
      setSelectedStudents((e) => [id, ...e]);
    }
  };
  const onSubmitHandler = async () => {
    const addToFormikStateHandler = async () => {
      await setFieldValue("students", [
        ...selectedStudents,
        ...values?.students,
      ]);
      setSelectedStudents([]);
    };
    if (isEdit && selectedStudents?.length > 0) {
      await assignQuizHandler(
        {
          quiz: values?._id,
          students: selectedStudents,
        },
        {
          async onSuccess(payload?: any) {
            await addToFormikStateHandler();
            setOpen(false);
          },
        }
      );
    } else {
      await addToFormikStateHandler();
      setOpen(false);
    }
  };
  const unAssignStudentHandler = async (id) => {
    const removeStudentHandler = async () => {
      let students = [...values?.students];
      students = students?.filter((e) => e !== id);
      await setFieldValue("students", students);
    };

    if (isEdit) {
      revokeStudentQuizHandler(
        {
          quiz: values?._id,
          student: id,
        },
        {
          onSuccess(payload?: any): any {
            removeStudentHandler();
          },
        }
      );
    } else {
      removeStudentHandler();
    }
  };

  const AssignedStudentDetails = () => {
    return (
      <>
        {values?.students?.length > 0 && (
          <div className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}>
            <b>Assigned Students</b>
            <div
              className={"w-full flex gap-2 overflow-x-scroll hideScroll pb-2"}
            >
              {values?.students?.map((e, key) => {
                const isAttempted = values?.attemptedStudent?.some(
                  (_e) => _e === e
                );
                return (
                  <AssignStudentDetailsCard
                    key={key}
                    canDelete={!isAttempted}
                    studentDetails={studentObject[e]}
                    removeHandler={() => unAssignStudentHandler(e)}
                  />
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };
  const SelectedStudentDetails = () => {
    return (
      <>
        {selectedStudents?.length > 0 && (
          <div className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}>
            <b>Selected Students</b>
            <div className={"w-full flex gap-2 overflow-x-scroll pb-2"}>
              {selectedStudents?.map((e, key) => {
                return (
                  <AssignStudentDetailsCard
                    canDelete={true}
                    key={key}
                    studentDetails={studentObject[e]}
                    removeHandler={() => selectStudentHandler(e)}
                  />
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <FieldArray
      name={"students"}
      render={() => {
        return (
          <>
            <div>
              <AssignedStudentDetails />
              <div>
                <MyButton
                  name={"Assign Students"}
                  onClick={() => setOpen(true)}
                />
              </div>
            </div>

            {isOpen && (
              <BoxModal
                title={"Assign Students"}
                closeHandler={() => setOpen(false)}
              >
                <div
                  className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}
                >
                  <SelectedStudentDetails />
                  <div className={"flex flex-col gap-5"}>
                    <b>Select Students</b>
                    <table className={"table"}>
                      <thead className={"table_head"}>
                        <th></th>
                        <th>ID</th>
                        <th>Name</th>
                      </thead>
                      <tbody>
                        {selectableStudent?.map((e, key) => {
                          const selectedIndex = selectedStudents?.findIndex(
                            (_e) => _e === e?._id
                          );
                          const isSelected = selectedIndex > -1 ? true : false;

                          return (
                            <tr
                              onClick={() => selectStudentHandler(e?._id)}
                              className={"table_row"}
                              key={key}
                            >
                              <td>
                                {isSelected ? (
                                  <CheckIcon
                                    className={"text-green-500 text-[18px]"}
                                  />
                                ) : (
                                  <UnCheckIcon
                                    className={"text-gray-500 text-[18px]"}
                                  />
                                )}
                              </td>
                              <td>{e?.studentID}</td>
                              <td>{`${e?.firstName} ${e?.lastName}`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <FormSubmitButtonsComponent
                    haveSubmitButton
                    submitButton={{
                      title: "OK",
                      type: "button",
                      async handler() {
                        await onSubmitHandler();
                      },
                    }}
                  />
                </div>
              </BoxModal>
            )}
          </>
        );
      }}
    />
  );
}

const AssignStudentDetailsCard = ({
  studentDetails,
  removeHandler,
  canDelete,
}) => {
  const DeleteIcon = getIconHelper(IconTypeEnum.DELETE);
  const CheckIcon = getIconHelper(IconTypeEnum.CHECK);

  return (
    <div
      className={
        "flex items-center gap-5 text-[14px] justify-between min-w-[200px] rounded-md cursor-pointer border-2 p-2"
      }
    >
      <div className={"flex items-center gap-2"}>
        <b>{studentDetails?.studentID}</b>
        <p className={"overflow-hidden"}>
          {`${studentDetails?.firstName || ""} ${
            studentDetails?.lastName || ""
          }`}
        </p>
      </div>
      <div className={""}>
        {canDelete ? (
          <DeleteIcon
            className={"text-red-500 text-[16px]"}
            onClick={() => removeHandler(studentDetails?._id)}
          />
        ) : (
          <CheckIcon className={"text-green-500 text-[16px]"} />
        )}
      </div>
    </div>
  );
};
export default AssignStudentsModal;
