import React, { useContext, useState } from "react";
import { IconTypeEnum, QuestionInterface } from "interfaces";
import { MyMoreOptionButton } from "components";
import { QuestionContext } from "context";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { getIconHelper } from "utils";

interface PropsInterface {
  question: QuestionInterface;
}
function QuestionCard({ question }: PropsInterface) {
  const [isToggle, setToggle] = useState(false);

  const navigate = useNavigate();
  const { deleteHandler, getAllDataHandlers, archiveHandler } =
    useContext(QuestionContext);
  const ToggleOn = getIconHelper(IconTypeEnum.TOGGLE_ON);
  const ToggleOff = getIconHelper(IconTypeEnum.TOGGLE_OFF);
  return (
    <div
      className={
        "flex flex-col bg-gray-100 p-2 rounded-md cursor-pointer hover:bg-opacity-50"
      }
      onClick={() => setToggle((e) => !e)}
    >
      <div className={"flex items-center justify-between gap-5"}>
        <div className={"flex items-start gap-2 p-2 "}>
          <span className={"font-semibold"}>{question?.questionID}.</span>
          <p className={"text-justify"}>{question?.question}</p>
        </div>
        <div className={"flex items-center gap-5"}>
          <div
            className={"text-[22px]"}
            onClick={(e) => {
              e?.stopPropagation();
              archiveHandler(question?._id, !question?.isArchived, {
                onSuccess(payload?: any): any {
                  getAllDataHandlers();
                },
              });
            }}
          >
            {question?.isArchived ? (
              <ToggleOff />
            ) : (
              <ToggleOn className={"text-tGreen"} />
            )}
          </div>
          <MyMoreOptionButton
            options={[
              question?._id && {
                name: "Edit",
                handler() {
                  navigate(PageLinks.questions.edit(question?._id));
                },
              },
              question?._id && {
                name: "Delete",
                handler() {
                  deleteHandler(question?._id, {
                    onSuccess(payload?: any): any {
                      getAllDataHandlers();
                    },
                  });
                },
              },
            ]}
          />
        </div>
      </div>

      {isToggle && (
        <div className={"grid grid-cols-2 gap-5 border-t-2 p-2 mt-5 pt-5"}>
          {question?.options?.map((e, key) => {
            return (
              <div
                className={`${
                  question?.correctAnswer === key + 1
                    ? "bg-tGreen bg-opacity-[0.1] text-tGreen"
                    : "bg-white text-black"
                } p-2 cursor-pointer rounded-md text-[14px]`}
              >
                {e?.text}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default QuestionCard;
