import React from "react";
import { Route } from "react-router-dom";
import { QuizPage, EditQuizPage } from "./pages";
import { ParamStringEnum, UserTypeEnum } from "../../interfaces";
import { AccessVerifier } from "../../templates";

function Index() {
  return (
    <Route path="/quiz">
      <Route
        path={""}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <QuizPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"create"}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <EditQuizPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamStringEnum.ID}`}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <EditQuizPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
