import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "services";
import { apiUrl } from "utils";
import { DataContextInterface, QuizAuditInterface } from "interfaces";
import { useDataContextHooks, usePaginationQueryHooks } from "hooks";
import generateQuizReport from "../../utils/report/generateQuizReport";

export const QuizReportContext = createContext<
  DataContextInterface<QuizAuditInterface>
>({
  isLoading: false,
  allData: [],
  isDetailsLoading: false,
  totalArchiveDocs: 0,
  totalDocs: 0,
  getAllDataHandlers(query?: any) {},
  generateCSV(query?: any) {},

});

function DataContextProvider({ children }) {
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi } = Api();

  //  handler
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);

        let res: any;
        res = await getApi(apiUrl.quiz.get_quizHistory, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    async generateCSV(query?: any) {
      try {
        setLoading(true);
        let res = await getApi(apiUrl.quiz.get_quizHistory, {
          ...query,
        });
        await generateQuizReport({
          data: (res?.data?.docs || []) as QuizAuditInterface[],
        });
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
  };



  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...contextHandlers,
  };
  return (
    <QuizReportContext.Provider value={contextValue}>
      {children}
    </QuizReportContext.Provider>
  );
}

export default DataContextProvider;
