const url = {
  auth: {
    get_currentUser: "/admin/user/current",
    post_login: "/admin/user/login",
  },
  file: {
    post_uploadFile: "/file/api/v1/upload",
    get_storageStatus: "/file/api/v1/get-available-space",
  },
  student: {
    get_student: "/student",
    get_studentCurrentProfile: "/student/current",
    get_studentDetails: (sID: string) => `/student/detail/${sID}`,
    post_student: `/student`,
    put_student: `/student/edit-profile`,
    post_login: "/student/login",
    delete_student: (sID: string) => `/student/delete/${sID}`,
  },
  quizSession: {
    get_pendingQuiz: "/quiz-session/pending",
    get_quizHistory: "/quiz-session/pending",
    get_quizResult: (sessionID) => `/quiz-session/result/${sessionID}`,
    put_startQuiz: "/quiz-session/start",
    put_submitAnswer: "/quiz-session/submit",
  },
  user: {
    get_user: "/admin/user",
    get_userDetails: (sID: string) => `/admin/user/detail/${sID}`,
    post_user: `/admin/user/register`,
    put_user: `/admin/user/edit-profile`,
    delete_user: (sID: string) => `/admin/user/delete/${sID}`,
  },
  question: {
    get_question: "/question",
    get_questionDetails: (id: string) => `/question/detail/${id}`,
    post_question: `/question`,
    put_question: `/question`,
    put_archiveQuestion: (id: string, status: boolean) =>
      `/question/archive/${id}/${status ? "on" : "off"}`,
    delete_question: (sID: string) => `/question/delete/${sID}`,
  },
  quiz: {
    get_quiz: "/quiz",
    get_quizHistory: "/quiz-session",
    get_quizDetails: (id: string) => `/quiz/detail/${id}`,
    post_quiz: `/quiz`,
    put_quiz: `/quiz`,
    put_assignStudentQuiz: `/quiz-session/assign/students`,
    put_revokeStudentQuiz: `/quiz-session/revoke`,
    delete_quiz: (sID: string) => `/quiz/delete/${sID}`,
  },
};
export default url;
