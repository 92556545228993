import React from "react";
import { MyButtonInterface } from "./buttons.interface";
import { getIconHelper } from "utils";

const MyButton: React.FC<MyButtonInterface> = ({
  name,
  isOutline,
  isLoading,
  iconType,
  colorType,
  className,
  iconClassName,
  size,
  disabled,
  type,
  ...props
}: MyButtonInterface) => {
  const getButtonStyle = () => {
    let styles, iconStyle;
    switch (colorType) {
      case "primary":
        if (isOutline) {
          styles = "bg-transparent border-primary  text-primary";
          iconStyle = "text-primary fill-primary";
        } else {
          iconStyle = "text-white";
          styles = "bg-primary  text-white ";
        }
        break;
      case "secondary":
        if (isOutline) {
          styles = "bg-transparent text-secondary  border-secondary";
          iconStyle = "text-secondary";
        } else {
          styles = "bg-secondary text-white";
          iconStyle = "text-white";
        }
        break;
      case "danger":
        if (isOutline) {
          styles = "border-red-500  text-red-500";
          iconStyle = "text-red-500";
        } else {
          styles = "bg-red-500  text-white ";
          iconStyle = "text-white";
        }
        break;
      case "white":
        if (isOutline) {
          styles = "border-black  text-black";
          iconStyle = "text-black";
        } else {
          styles = "bg-white  text-black";
          iconStyle = "text-black";
        }
        break;
      default:
        if (isOutline) {
          styles = "bg-transparent border-primary  text-primary";
          iconStyle = "text-primary";
        } else {
          iconStyle = "text-white";
          styles = "bg-primary  text-white ";
        }
        break;
    }
    return { styles, iconStyle };
  };
  const { styles, iconStyle } = getButtonStyle();
  const Icon = getIconHelper(iconType);
  const isContainRounded = className?.includes("rounded");

  return (
    <button
      className={`btn cursor-pointer  border-2 hover:bg-opacity-95
     ${
       size === "sm"
         ? "text-[12px] h-[25px]  px-3"
         : size === "md"
         ? "text-[14px] h-[30px]  px-3"
         : "text-[16px] h-[40px]  px-4"
     }   ${styles}  ${disabled && "cursor-not-allowed opacity-60"} ${
        !isContainRounded && "rounded-md"
      }   ${className || ""}`}
      type={type || "button"}
      {...props}
      disabled={disabled}
      onClick={disabled ? undefined : props?.onClick}
    >
      <div className={"flex  items-center justify-center  gap-2"}>
        {iconType && (
          <Icon
            data-testid={"btn-icon"}
            className={` text-[18px] ${iconStyle} `}
          />
        )}
        {name && (
          <span className={"whitespace-nowrap "}>
            {isLoading ? "Please wait..." : name}
          </span>
        )}
      </div>
    </button>
  );
};

export default MyButton;
