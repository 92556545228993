import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { AppContext, QuestionContext } from "context";
import { FieldArray, Form, Formik } from "formik";
import { QuestionFormik } from "../helpers";
import {
  MyCheckInput,
  FormSubmitButtonsComponent,
  MyTextAreaInput,
} from "components";
import { IconTypeEnum, ParamStringEnum } from "interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { PageLinks } from "routes";

function EditQuestionPage() {
  const navigate = useNavigate();
  const params = useParams<ParamStringEnum>();
  const editID = params.ID;
  const {
    handlers: { setLoadingState },
  } = useContext(AppContext);
  const { editDetailsHandler, getDetailsHandler, details } =
    useContext(QuestionContext);
  useEffect(() => {
    (async () => {
      if (editID) {
        setLoadingState(true);
        await getDetailsHandler(editID);
        setLoadingState(false);
      }
    })();
  }, [editID]);

  return (
    <PageTemplate title={editID ? "Edit Question" : "Add Question"}>
      <Formik
        validationSchema={QuestionFormik.validationSchema}
        enableReinitialize
        initialValues={QuestionFormik.initialValues(editID && details)}
        onSubmit={(values, props) =>
          editDetailsHandler(values, props, {
            onSuccess(payload?: any): any {
              navigate(PageLinks.questions.view);
            },
          })
        }
      >
        {({ values }) => {
          return (
            <Form className={"flex flex-col gap-20"}>
              <div className={"flex flex-col gap-5"}>
                <div>
                  <MyTextAreaInput
                    placeholder={"Enter Question"}
                    name={"question"}
                  />
                </div>
                <div className={" bg-gray-50 p-3 rounded-md"}>
                  <FieldArray
                    name={"options"}
                    render={({ insert, remove }) => {
                      return (
                        <div
                          className={
                            "grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-5"
                          }
                        >
                          {values?.options?.map((e, key) => {
                            return (
                              <div
                                key={key}
                                className={"flex items-start gap-5"}
                              >
                                <div className={"flex flex-1 flex-col gap-2"}>
                                  <MyTextAreaInput
                                    label={`Option ${key + 1}`}
                                    placeholder={`Option ${key + 1}`}
                                    name={`options.${key}.text`}
                                  />
                                  <MyCheckInput
                                    defaultValue={key + 1}
                                    iconType={IconTypeEnum.CHECK}
                                    name={"correctAnswer"}
                                    label={"Correct Answer"}
                                  />
                                </div>

                                {/*<div className={"flex items-center gap-2"}>*/}
                                {/*  <MyButton*/}
                                {/*    iconType={IconTypeEnum.ADD}*/}
                                {/*    isOutline*/}
                                {/*    colorType={"white"}*/}
                                {/*    onClick={() =>*/}
                                {/*      insert(key + 1, { text: "" })*/}
                                {/*    }*/}
                                {/*  />{" "}*/}
                                {/*  <MyButton*/}
                                {/*    colorType={"danger"}*/}
                                {/*    isOutline*/}
                                {/*    iconType={IconTypeEnum.DELETE}*/}
                                {/*    onClick={() => remove(key)}*/}
                                {/*  />*/}
                                {/*</div>*/}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <FormSubmitButtonsComponent haveSubmitButton haveCancelButton />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditQuestionPage;
