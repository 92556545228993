import React, { useContext } from "react";
import { PageLinks } from "routes";
import { NavLink, useNavigate } from "react-router-dom";
import { MyButton } from "components";
import { AppContext } from "context";
import { useAuthorizationHook } from "hooks";
import { UserTypeEnum } from "interfaces";

function TopNavComponent() {
  const {
    state: { profileDetails },
    handlers: { logoutHandler },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const accessByStudent = checkCanAccessHandler([UserTypeEnum.STUDENT]);
  const accessByAdmin = checkCanAccessHandler([UserTypeEnum.ADMIN]);
  const accessByAdminTeacher = checkCanAccessHandler([
    UserTypeEnum.ADMIN,
    UserTypeEnum.TEACHER,
  ]);

  const links = [
    accessByAdmin && {
      name: "Users",
      path: PageLinks.user.view,
    },
    accessByAdmin && {
      name: "Students",
      path: PageLinks.student.view,
    },
    accessByStudent && {
      name: "Assigned Quiz",
      path: PageLinks.quizSession.pendingQuiz,
    },
    accessByAdminTeacher && {
      name: "MCQ",
      path: PageLinks.questions.view,
    },
    accessByAdminTeacher && {
      name: "Quiz",
      path: PageLinks.quiz.view,
    },
    accessByAdminTeacher && {
      name: "Report",
      path: PageLinks.report.quizReport,
    },
  ];
  return (
    <div
      className={
        "flex px-10 sm:px-2 bg-white bg-white items-center justify-between gap-5"
      }
    >
      <div
        onClick={() => navigate("/home")}
        className={
          "text-[18px] sm:text-[16px] font-displayBold cursor-pointer uppercase text-primary"
        }
      >
        <b>MC Game</b>
      </div>
      <div className={"flex items-center gap-5 sm:gap-2"}>
        {links?.map((e, key) => {
          if (!e) return undefined;
          return (
            <NavLink
              key={key}
              className={({ isActive }) =>
                `${
                  isActive ? "text-primary" : "text-gray-400"
                } text-[14px] sm:text-[14px] font-semibold`
              }
              to={e?.path}
            >
              {e?.name}
            </NavLink>
          );
        })}
        {accessByStudent && (
          <div
            className={
              "flex flex-col gap-1 items-center bg-yellow-300 p-2 rounded-md font-bold cursor-pointer"
            }
          >
            <span className={"text-[18px] sm:text-[14px]"}>
              {profileDetails?.learnerCoin}
            </span>
            <span className={"text-[12px] sm:text-[10px]"}>Reward</span>
          </div>
        )}
        {accessByStudent && (
          <div
            className={
              "bg-gray-100  rounded-md p-2 sm:text-[12px] sm:p-1 cursor-pointer hover:bg-opacity-60 font-bold text-tBlue"
            }
          >
            {profileDetails?.studentID}
          </div>
        )}

        <MyButton
          size={"md"}
          isOutline
          name={"Logout"}
          onClick={logoutHandler}
        />
      </div>
    </div>
  );
}

export default TopNavComponent;
