import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { PageLinks } from "routes";
import { PageTemplate } from "templates";
import {
  MyTextAreaInput,
  MyTextField,
  FormSubmitButtonsComponent,
} from "components";
import { ParamStringEnum } from "interfaces";
import { AppContext, QuizContext } from "context";

import { AssignQuestionsModal, AssignStudentsModal } from "../components";
import { QuizFormik } from "../helpers";

function EditQuizPage() {
  const navigate = useNavigate();
  const params = useParams<ParamStringEnum>();
  const editID = params.ID;
  const {
    handlers: { setLoadingState },
  } = useContext(AppContext);
  const { editDetailsHandler, getDetailsHandler, details } =
    useContext(QuizContext);
  useEffect(() => {
    (async () => {
      if (editID) {
        setLoadingState(true);
        await getDetailsHandler(editID);
        setLoadingState(false);
      }
    })();
  }, [editID]);

  return (
    <PageTemplate title={editID ? "Edit Quiz" : "Add Quiz"}>
      <Formik
        validationSchema={QuizFormik.validationSchema}
        enableReinitialize
        initialValues={QuizFormik.initialValues(editID && details)}
        onSubmit={(values, props) =>
          editDetailsHandler(values, null, {
            onSuccess(payload?: any): any {
              navigate(PageLinks.quiz.view);
            },
          })
        }
      >
        <Form className={"flex flex-col gap-20 overflow-x-scroll hideScroll"}>
          <div className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}>
            <MyTextField placeholder={"Enter Quiz title"} name={"title"} />
            <MyTextAreaInput
              name={"description"}
              placeholder={"Enter quiz description"}
            />
            <AssignQuestionsModal />
            <AssignStudentsModal isEdit={editID ? true : false} />
          </div>
          <FormSubmitButtonsComponent haveSubmitButton haveCancelButton />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditQuizPage;
