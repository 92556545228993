import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { AppContext, StudentContext } from "context";
import { Form, Formik } from "formik";
import { StudentFormik } from "../helpers";
import { FormSubmitButtonsComponent, MyTextField } from "components";
import { ParamStringEnum } from "interfaces";
import { PageLinks } from "routes";

function EditStudentPage() {
  const navigate = useNavigate();
  const params = useParams<ParamStringEnum>();
  const editID = params.ID;
  const {
    handlers: { setLoadingState },
  } = useContext(AppContext);
  const { editDetailsHandler, getDetailsHandler, details } =
    useContext(StudentContext);
  useEffect(() => {
    (async () => {
      if (editID) {
        setLoadingState(true);
        await getDetailsHandler(editID);
        setLoadingState(false);
      }
    })();
  }, [editID]);
  const onSubmitHandler = (values, props) => {
    editDetailsHandler(values, props, {
      onSuccess(payload?: any) {
        navigate(PageLinks.student.view);
      },
    });
  };
  return (
    <PageTemplate title={editID ? "Edit Student" : "Add Student"}>
      <Formik
        validationSchema={StudentFormik.validationSchema}
        enableReinitialize
        initialValues={StudentFormik.initialValues(editID && details)}
        onSubmit={onSubmitHandler}
      >
        <Form className={"flex flex-col gap-20"}>
          <div className={"grid gap-5 items-start grid-cols-3"}>
            <MyTextField
              name={"firstName"}
              isRequired
              label={"First Name"}
              placeholder={"eg: John"}
            />
            <MyTextField
              name={"middleName"}
              label={"Middle Name"}
              placeholder={"eg: Kumar"}
            />
            <MyTextField
              isRequired
              name={"lastName"}
              label={"Last Name"}
              placeholder={"eg: Doe"}
            />
            <MyTextField
              name={"studentID"}
              label={"Students ID"}
              placeholder={"eg: ST-1"}
            />{" "}
            <MyTextField
              name={"email"}
              isRequired
              label={"Email"}
              placeholder={"eg: johndoe@gmail.com"}
            />
            <MyTextField
              name={"password"}
              label={"Password"}
              isRequired={editID ? false : true}
              placeholder={"Enter password"}
            />
            <div className={"col-span-2"}>
              <MyTextField
                name={"walletID"}
                label={"Wallet ID"}
                placeholder={"Enter wallet ID"}
              />
            </div>
          </div>
          <FormSubmitButtonsComponent haveSubmitButton haveCancelButton />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditStudentPage;
