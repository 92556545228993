import React from "react";
import { Route } from "react-router-dom";
import { EditQuestionPage, QuestionPage } from "./pages";
import { ParamStringEnum, UserTypeEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="/questions">
      <Route
        path={""}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <QuestionPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"create"}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <EditQuestionPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamStringEnum.ID}`}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <EditQuestionPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
