import React, { useContext, useEffect, useState } from "react";
import { IconTypeEnum, QuestionInterface, QuizInterface } from "interfaces";
import { FieldArray, useFormikContext } from "formik";
import { Api } from "services";
import { apiUrl, getIconHelper } from "utils";
import { AppContext } from "context";
import { BoxModal, MyButton, FormSubmitButtonsComponent } from "components";

function AssignQuestionsModal() {
  const [isOpen, setOpen] = useState(false);
  const CheckIcon = getIconHelper(IconTypeEnum.CHECK);
  const UnCheckIcon = getIconHelper(IconTypeEnum.UN_CHECK);
  const [questions, setQuestions] = useState<QuestionInterface[]>([]);
  const { values } = useFormikContext<QuizInterface<string>>();
  const [questionObject, setQuestionObject] = useState<{
    [qID: string]: Partial<QuestionInterface>;
  }>({});

  const { getApi } = Api();
  const DeleteIcon = getIconHelper(IconTypeEnum.DELETE);
  const {
    handlers: { setLoadingState, setErrorState },
  } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        setLoadingState(true);
        const res = await getApi(
          `${apiUrl.question.get_question}/?onlyActive=1`
        );
        const totalData = res?.data?.docs;
        setQuestions(totalData);
        let questionObj = {};
        totalData?.forEach((e) => {
          questionObj[e?._id] = e;
        });
        setQuestionObject(questionObj);
      } catch (err) {
        setErrorState(true, err?.message);
      } finally {
        setLoadingState(false);
      }
    })();
  }, []);

  return (
    <>
      <FieldArray
        name={"question"}
        render={({ remove, insert }) => {
          const SelectedQuestions = (
            <div className={"overflow-x-scroll hideScroll"}>
              {values?.question?.length > 0 && (
                <div className={"flex flex-col gap-5 overflow-x-scroll"}>
                  <b>Selected Question</b>
                  <div
                    className={
                      "w-full flex gap-2 overflow-x-scroll hideScroll pb-2"
                    }
                  >
                    {values?.question?.map((e, key) => {
                      return (
                        <div
                          key={key}
                          className={
                            "flex items-center gap-5 text-[14px] justify-between w-[200px] rounded-md cursor-pointer border-2 p-2"
                          }
                        >
                          <div className={"flex items-center gap-2"}>
                            <b>{questionObject[e]?.questionID || "-"}</b>
                            <p className={"w-[100px] truncate overflow-hidden"}>
                              {questionObject[e]?.question}
                            </p>
                          </div>
                          <DeleteIcon
                            className={"text-red-500 text-[16px]"}
                            onClick={() => remove(key)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
          return (
            <div className={"overflow-x-scroll hideScroll"}>
              <div className={"flex flex-col overflow-x-scroll hideScroll"}>
                {SelectedQuestions}
                <div>
                  <MyButton
                    name={"Assign Questions"}
                    onClick={() => setOpen(true)}
                  />
                </div>
              </div>
              {isOpen && (
                <BoxModal
                  title={"Assign Questions"}
                  closeHandler={() => setOpen(false)}
                >
                  <div
                    className={
                      "flex flex-col gap-5 overflow-x-scroll hideScroll"
                    }
                  >
                    {SelectedQuestions}
                    <div
                      className={
                        "flex flex-col gap-5 h-[200px] overflow-y-scroll"
                      }
                    >
                      <b>Select Questions</b>
                      <table className={"table"}>
                        <thead className={"table_head"}>
                          <th></th>
                          <th>QID</th>
                          <th>Question</th>
                        </thead>
                        <tbody>
                          {questions?.map((e, key) => {
                            const selectIndex = values?.question?.findIndex(
                              (_e) => _e === e?._id
                            );
                            const isSelected = selectIndex > -1 ? true : false;
                            const onSelectHandler = () => {
                              if (isSelected) {
                                remove(selectIndex);
                              } else {
                                insert(0, e?._id);
                              }
                            };
                            return (
                              <tr
                                onClick={() => onSelectHandler()}
                                className={"table_row"}
                                key={key}
                              >
                                <td>
                                  {isSelected ? (
                                    <CheckIcon
                                      className={"text-green-500 text-[18px]"}
                                    />
                                  ) : (
                                    <UnCheckIcon
                                      className={"text-gray-500 text-[18px]"}
                                    />
                                  )}
                                </td>
                                <td>{e?.questionID}</td>
                                <td>{e?.question}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <FormSubmitButtonsComponent
                      haveSubmitButton
                      submitButton={{
                        title: "OK",
                        type: "button",
                        handler() {
                          setOpen(false);
                        },
                      }}
                    />
                  </div>
                </BoxModal>
              )}
            </div>
          );
        }}
      />
    </>
  );
}

export default AssignQuestionsModal;
