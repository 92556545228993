import React from "react";
import { Route } from "react-router-dom";
import { LoginPage } from "./pages";
import { DataContextProvider } from "./context";

function Index() {
  return (
    <Route path="/" element={<DataContextProvider />}>
      <Route path={""} index element={<LoginPage isAdmin={false} />} />
      <Route path={"/admin"} element={<LoginPage isAdmin={true} />} />
    </Route>
  );
}

export default Index;
