import * as yup from "yup";
import { QuizInterface } from "interfaces";

export const QuizFormik = {
  initialValues: (values?: QuizInterface) => ({
    ...(values?._id && { _id: values?._id }),
    title: values?.title,
    description: values?.description,
    question: values?.question?.map((e) => e?._id) || [],
    students: values?.students?.map((e) => e) || [],
    attemptedStudent: values?.attemptedStudent?.map((e) => e) || [],
  }),
  validationSchema: yup.object().shape({
    title: yup.string().required("required"),
    description: yup.string(),
  }),
};
