import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import { Api } from "services";
import { apiUrl } from "utils";
import { QuizAuditInterface } from "interfaces";

import { HandlerCallbackInterface } from "interfaces";

interface QuizSessionContextInterface {
  isLoading: boolean;
  pendingQuiz: QuizAuditInterface[];
  quizSession: QuizAuditInterface;
  getPendingQuizHandler(query?: any);
  getQuizSessionHandler(sessionID: string): Promise<QuizAuditInterface>;
  startQuizSessionHandler(payload, action: HandlerCallbackInterface);
  submitAnswerHandler(payload, action: HandlerCallbackInterface);
}
export const QuizSessionContext = createContext<QuizSessionContextInterface>({
  isLoading: true,
  pendingQuiz: [],
  quizSession: undefined,
  getPendingQuizHandler(query?: any) {},
  getQuizSessionHandler(sessionID) {
    return undefined;
  },
  startQuizSessionHandler(payload, action: HandlerCallbackInterface) {},
  submitAnswerHandler(payload, action: HandlerCallbackInterface) {},
});

function DataContextProvider({ children }) {
  const [isLoading, setLoading] = useState(true);
  const [pendingQuiz, setPendingQuiz] = useState([]);
  const [quizSession, setQuizSession] = useState(undefined);

  const { handlers } = useContext(AppContext);
  const { getApi, putApi } = Api();

  //  handler
  const contextHandlers = {
    getPendingQuizHandler: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(apiUrl.quizSession.get_pendingQuiz, {
          ...query,
        });

        setPendingQuiz(res?.data?.docs);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getQuizSessionHandler: async (quizID: string) => {
      try {
        setLoading(true);
        let res: any;
        setQuizSession(undefined);
        res = await getApi(apiUrl.quizSession.get_quizResult(quizID));

        setQuizSession(res?.data);
        setLoading(false);
        return res?.data;
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
      return undefined;
    },
    startQuizSessionHandler: async (
      values,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoadingState(true);
        const res = await putApi(apiUrl.quizSession.put_startQuiz, values);

        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
    submitAnswerHandler: async (values, callback: HandlerCallbackInterface) => {
      try {
        handlers?.setLoadingState(true);
        const res = await putApi(apiUrl.quizSession.put_submitAnswer, values);

        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
  };

  const contextValue = {
    isLoading,
    pendingQuiz,
    quizSession,
    ...contextHandlers,
  };
  return (
    <QuizSessionContext.Provider value={contextValue}>
      {children}
    </QuizSessionContext.Provider>
  );
}

export default DataContextProvider;
