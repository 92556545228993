import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { QuizSessionContext } from "context";

import { useNavigate } from "react-router-dom";
import { MyButton } from "components";
import { PageLinks } from "routes";

function PendingQuizPage() {
  const {
    isLoading,
    pendingQuiz,
    getPendingQuizHandler,
    startQuizSessionHandler,
  } = useContext(QuizSessionContext);
  const navigate = useNavigate();
  useEffect(() => {
    getPendingQuizHandler();
  }, []);
  return (
    <PageTemplate isLoading={isLoading} title={"My Quiz"}>
      {pendingQuiz?.length === 0 && (
        <div
          className={
            "flex items-center  justify-center h-full text-center font-bold text-gray-300 text-[22px]"
          }
        >
          You have not assigned Quiz yet.
        </div>
      )}
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-10 "}>
        {pendingQuiz?.map((e, key) => {
          return (
            <div
              key={key}
              className={
                "flex flex-col gap-5 justify-between bg-opacity-[0.1] bg-blue-500 p-5 rounded-xl cursor-pointer border-white border-2 hover:border-blue-500"
              }
            >
              <div className={"flex flex-col gap-2"}>
                <b className={"text-[18px]"}>{e?.quiz?.title}</b>
                <p className={"text-justify text-[14px]"}>
                  {e?.quiz?.description}
                </p>
              </div>

              <div className={"flex gap-2 items-center"}>
                Questions:{" "}
                <div className={"p-2"}>{e?.quiz?.question?.length}</div>
              </div>
              <MyButton
                name={"Start Now"}
                onClick={async () => {
                  await startQuizSessionHandler(
                    {
                      quiz: e?.quiz?._id,
                    },
                    {
                      onSuccess(payload?: any): any {
                        navigate(PageLinks.quizSession.playQuiz(e?._id));
                      },
                    }
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    </PageTemplate>
  );
}

export default PendingQuizPage;
