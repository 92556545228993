const config = {
  development: {
    walletLink: (walletID: string) => `https://sepolia.etherscan.io/address/${walletID??''}`,
    default:
      "https://api.afblockchaingame.com/api/v1",
  },

  production: {
    walletLink: (walletID: string) => `https://sepolia.etherscan.io/address/${walletID??''}`,
    default: process.env.REACT_APP_API_HOST_URL,
  },
};

export default process.env.NODE_ENV === "development"
  ? config["development"]
  : config["production"];
