import React from "react";
import {PageTemplateInterface} from "./template.interface";
import { IconTypeEnum } from "interfaces";
import {MyButton} from "../components";

function PageTemplate({
                        children,
                        rightChildren,
                        title,
                        isLoading,
                        exportXlsx
                      }: PageTemplateInterface) {
  return (
      <div
      className={"grid  px-10 sm:px-2 pt-10 sm:pt-5 "}
      style={{
        gridTemplateRows: "auto 1fr",
      }}
    >
        <div className={"flex justify-between gap-10 items-center"}>
          <b className={"font-displayBold text-[22px]"}>{title}</b>
          <div>
              {typeof exportXlsx?.generateHandler === 'function' && (
                  <div className={`${exportXlsx?.havePaddingTop && "mt-8"}`}>
                      <MyButton
                          iconType={IconTypeEnum.DOWNLOAD}
                          isOutline
                          onClick={exportXlsx?.generateHandler}
                          name={"Export .XLSX"}
                      />
                  </div>
              )}
            {rightChildren}
          </div>
        </div>
      {isLoading ? (
        <div className={"flex flex-col gap-5 items-center justify-center"}>
          <span
            className={"font-bold text-[24px] sm:text-[14px] text-blue-300 "}
          >
            Please wait...
          </span>
        </div>
      ) : (
        <div
          className={`bg-white p-5 sm:p-2 mt-5 overflow-scroll hideScroll rounded-md`}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default PageTemplate;
