import React from "react";
import {
  AppContextProvider,
  ConfirmContextProvider,
  QuizContextProvider,
  AdminContextProvider,
  StudentContextProvider,
  QuestionContextProvider,
  QuizSessionContextProvider,
  QuizReportContextProvider,
} from "./context";
import { AuthRoutes } from "./routes";
import Layout from "layout";
import "./index.css";

const App = () => {
  return (
      <ConfirmContextProvider>
        <AppContextProvider>
          <AdminContextProvider>
            <StudentContextProvider>
              <QuestionContextProvider>
                <QuizContextProvider>
                  <QuizSessionContextProvider>
                    <QuizReportContextProvider>
                      <Layout>
                        <AuthRoutes />
                      </Layout>
                    </QuizReportContextProvider>
                  </QuizSessionContextProvider>
                </QuizContextProvider>
              </QuestionContextProvider>
            </StudentContextProvider>
          </AdminContextProvider>
        </AppContextProvider>
      </ConfirmContextProvider>
  );
};

export default App;
