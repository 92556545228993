import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { QuizInterface } from "interfaces";
import { MyMoreOptionButton } from "components";
import { QuizContext } from "context";
import { PageLinks } from "routes";
interface PropsInterface {
  quiz: QuizInterface;
}
function QuizCard({ quiz }: PropsInterface) {
  const navigate = useNavigate();
  const { deleteHandler, getAllDataHandlers } = useContext(QuizContext);
  return (
    <div
      className={
        "flex flex-col bg-gray-100 p-2 rounded-md cursor-pointer hover:bg-opacity-50"
      }
    >
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-center gap-2 p-2 "}>
          <span className={"font-semibold"}>{quiz?.quizID}.</span>
          <p>{quiz?.title}</p>
        </div>
        <div className={"flex items-center gap-5"}>
          {/*<div className={"flex items-center gap-2 p-2 rounded-md text-[14px]"}>*/}
          {/*  <span className={"text-black"}>Assigned Students:</span>*/}
          {/*  <b className={"text-tBlue"}>{quiz?.students?.length}</b>*/}
          {/*</div>{" "}*/}
          <div className={"flex items-center gap-2 p-2 rounded-md text-[14px]"}>
            <span className={"text-black"}>Assigned Questions:</span>
            <b className={"text-tGreen"}>{quiz?.question?.length}</b>
          </div>
          <MyMoreOptionButton
            options={[
              quiz?._id && {
                name: "Edit",
                handler() {
                  navigate(PageLinks.quiz.edit(quiz?._id));
                },
              },
              quiz?._id && {
                name: "Delete",
                handler() {
                  deleteHandler(quiz?._id, {
                    onSuccess(payload?: any): any {
                      getAllDataHandlers();
                    },
                  });
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default QuizCard;
