import React from "react";

import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import {QuizAuditInterface} from "interfaces";
import moment from "moment/moment";

interface PropsInterface {
    data?: QuizAuditInterface[];
}

async function generateQuizReport({data}: PropsInterface) {
    try {
        //    create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Student Report", {});
        worksheet.properties.defaultRowHeight = 30;
        worksheet.properties.defaultColWidth = 30;
        let tableHeaders = [
            {
                name: "SN.",
            },
            {
                name: "Quiz ID",
            },
            {
                name: "Student ID",
            },
            {
                name: "Student Name",
            },
            {
                name: "Result",
            },
            {
                name: "Learner's Coin Earned",
            },
            {
                name: "Quiz Assigned At ",
            },
            {
                name: "Quiz Completed At ",
            }
        ];
        let tableRows = data?.map((renderData, index) => {
            return [
                index + 1,
                renderData?.quiz?.quizID,
                renderData?.student?.studentID,
                `${renderData?.student?.firstName || ""} ${
                    renderData?.student?.middleName || ""
                } ${renderData?.student?.lastName || ""}`,
                `${renderData?.correctAnswerCount}/${renderData?.quiz?.question?.length}`,
                renderData?.totalPointReceived,
                renderData?.assignedAt ? moment(renderData?.assignedAt).format("YYYY/MM/DD hh:mm A") : '',
                renderData?.isCompleted ? moment(renderData?.completedAt).format("YYYY/MM/DD hh:mm A") : ''
            ];
        });
        // add a table to a sheet
        worksheet.addTable({
            name: "Quiz Report",
            ref: "A1",
            headerRow: true,
            totalsRow: false,
            style: {
                showRowStripes: false,
            },
            columns: tableHeaders,
            rows: tableRows,
        });
        //    save the workbook to a file
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace(/[-T:]/g, '_').slice(0, -5);
        const filename = `report_${formattedDate}.xlsx`;

        const bufferFile = await workbook.xlsx.writeBuffer();
        await FileSaver.saveAs(
            new Blob([bufferFile], {type: "application/octet-stream"}),
            filename
        );
    } catch (err) {
        throw err;
    }
}

export default generateQuizReport;
