import React from "react";
import { useField } from "formik";
import "./styles.css";
import { MyCheckFieldInterface } from "./inputs.interface";
import { IconTypeEnum } from "interfaces";
import { getIconHelper } from "utils";

export default function MyCheckInput({
  label,
  defaultValue,
  iconType,
  inputSize,
  ...props
}: MyCheckFieldInterface) {
  const [field, meta, helpers] = useField(props);
  const isMultiple = Array.isArray(field?.value);
  let CheckIcon = getIconHelper(IconTypeEnum.CHECK);
  let UnCheckIcon = getIconHelper(IconTypeEnum.UN_CHECK);
  const onClickHandler = () => {
    if (isMultiple) {
      let tempField = [...field?.value];
      let isAlready = tempField?.some((e) => e === defaultValue);
      if (isAlready) {
        helpers.setValue(tempField?.filter((e) => e !== defaultValue));
      } else {
        tempField?.push(defaultValue);
        helpers.setValue(tempField);
      }
    } else {
      if (field?.value === defaultValue) {
        helpers.setValue("");
      } else {
        helpers.setValue(defaultValue);
      }
      return;
    }
  };
  const isSelected = () => {
    let status;
    if (isMultiple) {
      status = field?.value?.some((e) => e === defaultValue);
    } else {
      status = defaultValue === field?.value;
    }

    return status;
  };
  return (
    <div
      className={"flex items-center gap-2 cursor-pointer select-none"}
      onClick={!isSelected() && onClickHandler}
    >
      <div className={"text-[20px]"}>
        {isSelected() ? (
          <CheckIcon
            data-testid={"check-input-checkbox-icon"}
            className={"text-tGreen"}
          />
        ) : (
          <UnCheckIcon
            data-testid={"check-input-checkbox-icon"}
            className={"text-gray-500"}
          />
        )}
      </div>
      <span
        className={`input_label  ${
          inputSize === "sm"
            ? "text-[12px]"
            : inputSize === "md"
            ? "text-[14px]"
            : "text-[16px]"
        }`}
      >
        {label}
      </span>
    </div>
  );
}
