import React from "react";
import { Route } from "react-router-dom";
import { PlaygroundPage, PendingQuizPage } from "./pages";
import { ParamStringEnum, UserTypeEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="/quiz-session">
      <Route
        path={""}
        element={
          <AccessVerifier role={[UserTypeEnum.STUDENT]}>
            <PendingQuizPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`play/:${ParamStringEnum.ID}`}
        element={
          <AccessVerifier role={[UserTypeEnum.STUDENT]}>
            <PlaygroundPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
