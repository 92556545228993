import * as yup from "yup";
import { StudentInterface } from "interfaces";

export const StudentFormik = {
  initialValues: (values?: StudentInterface) => ({
    ...(values?._id && { _id: values?._id }),
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    description: values?.description,
    email: values?.email,
    password: values?.password,
    walletID: values?.walletID,
    studentID: values?.studentID,
  }),
  validationSchema: yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().required("required"),
    password: yup.string(),
  }),
};
