import React, { useContext } from "react";
import { Form, Formik } from "formik";

import LoginImage from "assets/images/loginImage.png";

import { DataContext } from "../context";
import { LoginFormik } from "../helpers";
import { MyButton, MyPasswordInput, MyTextField } from "components";

function LoginPage({ isAdmin }) {
  const { loginHandler } = useContext(DataContext);

  return (
    <div className={"grid grid-cols-12 sm:grid-cols-1 h-[100vh] "}>
      <div
        className={
          "flex flex-col justify-around col-span-7 p-10 bg-blue-500  text-white sm:hidden"
        }
      >
        <div className={"flex items-center justify-center"}>
          <img
            alt={"login"}
            src={LoginImage}
            className={"object-contain h-[400px] w-[400px]"}
          />
        </div>
        <div className={"flex flex-col gap-2 items-center"}>
          <h1 className=" font-displayBold text-[22px]">
            Powered by Sepolia Network
          </h1>
          <p className={"text-[16px] text-center"}>
            "Collaborative Learning and Co-creation and LearnerCoin"
          </p>
        </div>
      </div>
      <div className={"grid col-span-5 bg-white px-20 sm:px-5"}>
        <Formik
          onSubmit={loginHandler}
          validationSchema={LoginFormik.validationSchema}
          initialValues={LoginFormik.initialValues(isAdmin)}
        >
          {({ isSubmitting, errors }) => {
            return (
              <Form
                className={"flex flex-col  justify-center gap-5  text-[14px] "}
              >
                <div
                  className={
                    "flex flex-col w-full justify-start gap-2 items-start text-left"
                  }
                >
                  <h1 className={"font-semibold text-[20px]"}>
                    {isAdmin ? "Admin Login" : "Student Login"}
                  </h1>
                </div>
                <div className={"flex flex-col gap-4 w-full"}>
                  <span
                    className={"text-[14px] text-tRed"}
                    data-testid={"login-error-message"}
                  >
                    {typeof errors?.error === "string" && `*${errors?.error}`}
                  </span>
                  <MyTextField
                    name={"email"}
                    label={"Email"}
                    isRequired
                    placeholder={"username or email"}
                  />
                  <MyPasswordInput
                    name={"password"}
                    label={"Password"}
                    isRequired
                    placeholder={"*****"}
                  />
                </div>
                <MyButton
                  className={"w-full"}
                  name={"Login"}
                  type={"submit"}
                  isLoading={isSubmitting}
                />
                <div
                  className={
                    "flex flex-col gap-2 text-gray-500 items-center mt-5 invisible sm:visible"
                  }
                >
                  <h1 className=" font-semibold text-[14px]">
                    Powered by Sepolia Network
                  </h1>
                  <p className={"text-[12px] text-center"}>
                    "Collaborative Learning and Co-creation and LearnerCoin"
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default LoginPage;
