import React from "react";

import { getIconHelper } from "utils";
import { IconTypeEnum } from "interfaces";

import { BoxModalInterface } from "./interfaces";

export default function ModalComponent({
  title,
  closeHandler,
  children,
}: BoxModalInterface) {
  const CloseIcon = getIconHelper(IconTypeEnum.CLOSE);
  return (
    <div className="fixed flex items-start pt-20 sm:px-4 justify-center h-screen w-screen overflow-scroll hideScroll  left-0 top-0 z-[9999] bg-gray-700 bg-opacity-70">
      <div
        className={`bg-white w-[800px] sm:w-[500px] rounded-md p-5 overflow-x-scroll hideScroll `}
      >
        <div
          className={`flex justify-between items-center ${
            title && "border-b-2"
          }  pb-2`}
        >
          <span className={"font-bold text-[18px] text-primary"}>{title}</span>
          <CloseIcon
            data-testid={"box-modal-close-button"}
            className={"text-[40px] text-gray-300 cursor-pointer"}
            onClick={() => closeHandler()}
          />
        </div>
        <div className="p-2 py-5  min-h-[20vh] max-h-[60vh] overflow-x-scroll hideScroll">
          {children}
        </div>
      </div>
    </div>
  );
}
