import React from "react";

import LoginImage from "assets/images/loginImage.png";

function HomePage() {
  return (
    <div className={"flex flex-col justify-around bg-blue-50  text-blue-500 "}>
      <div className={"flex items-center justify-center"}>
        <img
            alt={"login"}
          src={LoginImage}
          className={"object-contain h-[400px] w-[400px]"}
        />
      </div>
      <div className={"flex flex-col gap-2 items-center"}>
        <h1 className=" font-displayBold text-[22px]">
          Powered by Sepolia Network
        </h1>
        <p className={"text-[16px] text-center"}>
          "Collaborative Learning and Co-creation and LearnerCoin"
        </p>
      </div>
    </div>
  );
}

export default HomePage;
