import React from "react";
import { useField } from "formik";
import { MyTextFieldInterface } from "./inputs.interface";
import "./styles.css";

export default function MyTextField({
  label,
  isRequired,
  inputSize,
  ...props
}: MyTextFieldInterface) {
  let [field, meta] = useField(props);
  let isError = meta.touched && meta.error;
  return (
    <div className={`input_container`}>
      {label && (
        <label
          htmlFor={props?.name}
          className={`input_label  ${
            inputSize === "sm"
              ? "text-[12px]"
              : inputSize === "md"
              ? "text-[14px]"
              : "text-[16px]"
          }`}
        >
          {label}
          {isRequired && <span className={"text-red-500 "}>*</span>}
        </label>
      )}
      <input
        {...field}
        {...props}
        id={props?.name}
        className={` input_style rounded-md border-gray-200 border-2  ${
          isError && "border-red-500"
        }   ${
          inputSize === "sm"
            ? "text-[12px]  h-[30px]"
            : inputSize === "md"
            ? "text-[14px]  min-h-[35px]"
            : "text-[16px] min-h-[40px]"
        }`}
      />

      {isError && <span className={`input_textError`}>* {meta.error}</span>}
    </div>
  );
}
