export enum QueryStringEnum {
  Archive = "archive",
  CURRENT_PAGE = "currentPage",
}
export enum ParamStringEnum {
  ID = "ID",
}
export enum AdminRoleEnum {
  ADMIN = "admin",
  TEACHER = "teacher",
}

export enum UserTypeEnum {
  STUDENT = "student",
  ADMIN = "admin",
  TEACHER = "teacher",
}

export enum IconTypeEnum {
  ADD = "ADD",
  CLOSE = "CLOSE",
  CLOCK = "CLOCK",
  DELETE = "DELETE",
  TOGGLE_ON = "TOGGLE_ON",
  TOGGLE_OFF = "TOGGLE_OFF",
  CHECK = "CHECK",
  UN_CHECK = "UN_CHECK",
  PASSWORD = "PASSWORD",
  DOWNLOAD = "DOWNLOAD",
}

export enum AuthTokenENUM {
  accessToken = "accessToken",
  role = "role",
}
