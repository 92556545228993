import React from "react";
import { Route } from "react-router-dom";
import { QuizReportPage } from "./pages";
import { UserTypeEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="/reports">
      <Route
        path={""}
        element={
          <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
            <QuizReportPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
