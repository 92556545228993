import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { QuestionContext } from "context";
import { QuestionCard } from "content";
import { MyButton, PaginationComponent } from "components";
import { IconTypeEnum } from "interfaces";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";

function QuestionPage() {
  const { getAllDataHandlers, isLoading, allData, totalDocs } =
    useContext(QuestionContext);
  const navigate = useNavigate();
  useEffect(() => {
    getAllDataHandlers();
  }, []);
  return (
    <PageTemplate
      isLoading={isLoading}
      title={"Questions"}
      rightChildren={
        <div>
          <MyButton
            name={"Add Question"}
            onClick={() => navigate(PageLinks.questions.create)}
            iconType={IconTypeEnum.ADD}
          />
        </div>
      }
    >
      <div className={"flex flex-col gap-5"}>
        {allData?.map((e, key) => {
          return <QuestionCard question={e} key={key} />;
        })}
        <PaginationComponent totalDocs={totalDocs} />
      </div>
    </PageTemplate>
  );
}

export default QuestionPage;
