import React from "react";
import { Route, Routes } from "react-router-dom";

import { PrivateRouteComponent, PublicRouteComponent } from "./components";
import {
  AuthRoutes,
  UtilsPageRoutes,
  QuestionRoutes,
  QuizRoutes,
  StudentRoutes,
  UserRoutes,
  QuizSessionRoutes,
  QuizReportRoutes,
} from "../modules";

export default function AuthRoute() {
  return (
    <Routes>
      <Route path="/" element={<PublicRouteComponent />}>
        {AuthRoutes()}
      </Route>
      <Route path="/" element={<PrivateRouteComponent />}>
        {UtilsPageRoutes()}
        {QuestionRoutes()}
        {QuizRoutes()}
        {StudentRoutes()}
        {UserRoutes()}
        {QuizSessionRoutes()}
        {QuizReportRoutes()}
      </Route>
    </Routes>
  );
}
